<template>
  <div class="tab-contents-wrap">
    <div class="title-wrap">
      <h5>
        <template
          v-for="(code, index) in rooms"
        >{{$t(`commonCode.${code}`)}}{{(rooms.length > index + 1) ? ' & ' :''}}</template>
      </h5>
      <span
        class="date"
      >{{`${startTime.format('YYYY.M.DD(ddd) HH:mm')} - ${endTime.format('HH:mm')}`}}</span>
    </div>

    <template v-if="statusType < 4">
      <!-- Step -->
      <div class="step-wrap">
        <ol class="step">
          <li :class="{ on: statusType === 1}">{{$t('content.event.EventReservation.txt76')}}</li>
          <!-- 해당 step에 on 클래스 추가 -->
          <li :class="{ on: statusType === 2}">{{$t('content.event.EventReservation.txt77')}}</li>
          <li :class="{ on: statusType === 3}">{{$t('content.event.EventReservation.txt78')}}</li>
        </ol>
      </div>
      <!-- Step -->
      <!-- Form -->
      <div class="form-container">
        <ValidationObserver ref="form">
          <!-- step1 -->
          <template v-if="statusType === 1">
            <form>
              <fieldset>
                <legend>{{$t('content.event.EventReservation.txt79')}}</legend>
                <div class="align-right" v-html="$t('content.event.EventReservation.txt80')">
                </div>
                <ul>
                  <li class="form-row">
                    <ValidationProvider
                      :rules="{ required: true }"
                      :name="$t('content.event.EventReservation.txt81')"
                      v-slot="{ errors }"
                    >
                      <BaseInput
                        v-model="eventName"
                        :label="$t('content.event.EventReservation.txt81')"
                        :placeholder="$t('content.event.EventReservation.txt82')"
                        :class="{'error':errors[0]}"
                        maxlength="20"
                        required
                      />
                      <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                      <span class="input-desc">{{$t('content.event.EventReservation.txt83')}}</span>
                    </ValidationProvider>
                  </li>
                  <li class="form-row">
                    <ValidationProvider
                      :rules="{ required: true }"
                      :name="$t('content.event.EventReservation.txt84')"
                      v-slot="{ errors }"
                    >
                      <BaseTextarea
                        :label="$t('content.event.EventReservation.txt84')"
                        v-model="eventDesc"
                        :placeholder="$t('content.event.EventReservation.txt85')"
                        size="large"
                        :class="{'error':errors[0]}"
                        required
                      />
                      <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                    </ValidationProvider>
                  </li>
                  <li class="form-row">
                    <ValidationProvider
                      :rules="{ required: true }"
                      :name="$t('content.event.EventReservation.txt86')"
                      v-slot="{ errors }"
                    >
                      <BaseTextarea
                        :label="$t('content.event.EventReservation.txt86')"
                        v-model="sponsor"
                        :placeholder="$t('content.event.EventReservation.txt87')"
                        :class="{'error':errors[0]}"
                        required
                      />
                      <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                    </ValidationProvider>
                  </li>
                  <li class="form-row">
                    <BaseTextarea
                      :label="$t('content.event.EventReservation.txt88')"
                      v-model="eventParticipantIntroduce"
                      :placeholder="$t('content.event.EventReservation.txt89')"
                    />
                  </li>
                  <li class="form-row">
                    <ValidationProvider
                    :rules="{ required: true }"
                    :name="$t('content.event.EventReservation.txt90')"
                    v-slot="{ errors }"
                  >
                      <BaseInput
                        type="number"
                        :label="$t('content.event.EventReservation.txt90')"
                        v-model="expectParticipantCount"
                        :placeholder="$t('content.event.EventReservation.txt91')"
                        :class="{'error':errors[0]}"
                        required
                      />
                      <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                    </ValidationProvider>
                  </li>
                </ul>
              </fieldset>
            </form>
          </template>
          <!-- step1 -->
          <!-- step2 -->
          <template v-else-if="statusType === 2">
            <form>
              <fieldset>
                <legend>{{$t('content.event.EventReservation.txt92')}}</legend>
                <div class="align-right" v-html="$t('content.event.EventReservation.txt80')">
                </div>
                <ul>
                  <template
                    v-if="!(userInfo.member_role === 'MB202'
                  || !!userInfo.external_company_no)
                  || rooms.includes('6')"
                  >
                    <li class="form-row">
                      <BaseLabelElement :label="$t('content.event.EventReservation.txt93')" />
                      <BaseRadioButton
                        name="useMic"
                        v-model="useMic"
                        :value=true
                      >{{$t('content.event.EventReservation.txt94')}}</BaseRadioButton>
                      <BaseRadioButton
                        name="useMic"
                        v-model="useMic"
                        :value=false
                      >{{$t('content.event.EventReservation.txt95')}}</BaseRadioButton>
                    </li>
                    <li class="form-row">
                      <BaseLabelElement :label="$t('content.event.EventReservation.txt96')" />
                      <ValidationProvider
                        vid="codeSeatSetting"
                        rules=""
                        v-slot="x">
                        <BaseRadioButton
                          name="codeSeatSetting"
                          v-model="codeSeatSetting"
                          :value="3551"
                          @change="otherSeatSetting=''"
                          :disabled="!rooms.includes('6')"
                        >{{$t('content.event.EventReservation.txt97')}}</BaseRadioButton>
                        <BaseRadioButton
                          name="codeSeatSetting"
                          v-model="codeSeatSetting"
                          :value="3552"
                          @change="otherSeatSetting=''"
                          :disabled="!rooms.includes('6')"
                        >{{$t('content.event.EventReservation.txt98')}}</BaseRadioButton>
                        <BaseRadioButton
                          name="codeSeatSetting"
                          v-model="codeSeatSetting"
                          :value="3553"
                          @change="otherSeatSetting=''"
                        >{{$t('content.event.EventReservation.txt42')}}</BaseRadioButton>
                      </ValidationProvider>
                      <ValidationProvider
                        :rules="{ requiredIf: { target: 'codeSeatSetting', values: '3553' } }"
                        :name="$t('content.event.EventReservation.txt42')"
                        v-slot="{ errors }"
                      >
                        <BaseInput
                          type="text"
                          v-model="otherSeatSetting"
                          :placeholder="$t('content.event.EventReservation.txt99')"
                          :class="{'error':(errors[0] && codeSeatSetting == 3553)}"
                          required
                        />
                        <span v-if="errors[0] && codeSeatSetting == 3553 && $i18n.locale === 'ko'" class="input-error-desc">{{ $t('content.event.EventReservation.txt42') }}은 필수입력 항목입니다.</span>
                        <span v-if="errors[0] && codeSeatSetting == 3553 && $i18n.locale === 'en'" class="input-error-desc">{{ $t('content.event.EventReservation.txt42') }} is required.</span>
                        <span class="input-desc">{{$t('content.event.EventReservation.txt100')}}</span>
                      </ValidationProvider>
                      <!-- <BaseInputElement
                        v-model="otherSeatSetting"
                        :placeholder="$t('content.event.EventReservation.txt99')"
                      /> -->
                    </li>
                    <li class="form-row">
                      <BaseLabelElement :label="$t('content.event.EventReservation.txt135')" />
                      <BaseCheckbox v-model="useTool[0]"
                        style="display:inline-block; margin-right: 50px;"
                        trueValue="3500">
                        HDMI
                      </BaseCheckbox>
                      <BaseCheckbox v-model="useTool[1]"
                        style="display:inline-block; margin-right: 50px;"
                        trueValue="3501">
                        {{$t('content.event.EventReservation.txt130')}}
                      </BaseCheckbox>
                      <BaseCheckbox v-model="useTool[2]"
                        style="display:inline-block; margin-right: 50px;"
                        trueValue="3502"
                        >
                        {{$t('content.event.EventReservation.txt131')}}
                      </BaseCheckbox>
                      <BaseCheckbox v-model="useTool[3]"
                        style="display:inline-block; margin-right: 50px;"
                        trueValue="3503"
                        >
                        {{$t('content.event.EventReservation.txt132')}}
                      </BaseCheckbox>
                      <BaseCheckbox v-model="useTool[4]"
                        style="display:inline-block; margin-right: 50px;"
                        trueValue="3504">
                        {{$t('content.event.EventReservation.txt133')}}
                      </BaseCheckbox>
                      <BaseCheckbox v-model="useTool[5]"
                        style="display:inline-block; margin-right: 50px;"
                        trueValue="3505">
                        {{$t('content.event.EventReservation.txt134')}}
                      </BaseCheckbox>
                      <span class="input-desc">{{$t('content.event.EventReservation.txt136')}}</span>
                    </li>
                    <li class="form-row">
                      <BaseLabelElement :label="$t('content.event.EventReservation.txt101')" />
                      <BaseRadioButton
                        name="isBringCatering"
                        v-model="isBringCatering"
                        :value="false"
                      >{{$t('content.event.EventReservation.txt102')}}</BaseRadioButton>
                      <BaseRadioButton name="isBringCatering" v-model="isBringCatering" :value=true>
                        {{$t('content.event.EventReservation.txt103')}}
                      </BaseRadioButton>
                      <span class="input-desc">{{$t('content.event.EventReservation.txt104')}}</span>
                    </li>
                  </template>
                  <li class="form-row">
                    <div class="terms-area">
                      <div class="terms-container">

                        <ReservationLegal></ReservationLegal>


                      </div>
                      <div class="checkbox-container">
                        <BaseCheckbox v-model="spaceAgreement" required>
                          {{$t('content.event.EventReservation.txt105')}}
                        </BaseCheckbox>
                      </div>
                    </div>
                  </li>
                </ul>
              </fieldset>
            </form>
          </template>
          <!-- step2 -->
          <!-- step3 -->
          <template v-else-if="statusType === 3">
            <form>
              <fieldset>
                <legend>{{$t('content.event.EventReservation.txt106')}}</legend>
                <ul>
                  <li class="form-row">
                    <ValidationProvider
                      :rules="{ required: true, phone: true }"
                      :name="$t('content.event.EventReservation.txt107')"
                      v-slot="{ errors }"
                    >
                      <TelInput
                        v-model="mobileNumber"
                        :label="$t('content.event.EventReservation.txt107')"
                        :placeholder="$t('content.event.EventReservation.txt108')"
                        :disabled="!enabledInput"
                        :class="{'error':errors[0]}"
                        required
                        maxlength="13"
                      />
                      <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                    </ValidationProvider>
                  </li>
                  <li class="form-row">
                    <BaseInput
                      v-model="otherInquiry"
                      :label="$t('content.event.EventReservation.txt109')"
                      :placeholder="$t('content.event.EventReservation.txt110')"
                      maxlength="100"
                    />
                    <span class="input-desc">{{$t('content.event.EventReservation.txt111')}}</span>
                  </li>
                  <li class="form-row">
                    <FileInput
                      ref="attachementFile"
                      :label="$t('content.event.EventReservation.txt112')"
                      v-model="fileList"
                      :placeholder="$t('content.event.EventReservation.txt112')"
                      :buttonLabel="$t('content.event.EventReservation.txt112_1')"
                      accept=".hwp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .csv, .jpg, .jpeg, .gif, .png, .bmp, .pdf"
                      @change="(file) => selectFile(file)"
                      @remove="removeFile"
                    />
                  </li>
                  <li class="form-row">
                    <div class="terms-area">
                      <div class="terms-container">
                        <ol>
                          <li>{{$t('content.event.EventReservation.txt114')}}</li>
                          <li>{{$t('content.event.EventReservation.txt115')}}</li>
                          <li>
                            {{$t('content.event.EventReservation.txt116')}}
                            <div class="period" v-html="$t('content.event.EventReservation.txt117')">

                            </div>
                          </li>
                        </ol>
                      </div>
                      <div class="checkbox-container">
                        <BaseCheckbox v-model="privatedAgreement" required>
                          {{$t('content.event.EventReservation.txt118')}}
                        </BaseCheckbox>
                      </div>
                    </div>
                  </li>
                </ul>
              </fieldset>
            </form>
          </template>
          <!-- step3 -->
        </ValidationObserver>
      </div>
      <!-- // Form -->
      <!-- 버튼 -->
      <div class="btn-wrap perspective">
        <a href="#" @click="goBack" class="back">Back</a>
        <template v-if="statusType === 1">
          <button
            @click="changePage(2)"
            :disabled="eventName.length === 0 || eventDesc.length === 0
          || sponsor.length === 0 || expectParticipantCount.length === 0"
            type="button"
            class="btn-basic"
          >{{$t('content.common.button.btn02')}}</button>
        </template>
        <template v-else-if="statusType === 2">
          <button
            @click="changePage(1)"
            type="button"
            class="btn-ghost"
          >{{$t('content.common.button.btn03')}}</button>
          <button
            @click="changePage(3)"
            :disabled="!spaceAgreement || (codeSeatSetting === 3553 && !otherSeatSetting)"
            type="button"
            class="btn-basic"
          >{{$t('content.common.button.btn02')}}</button>
        </template>
        <template v-else-if="statusType === 3">
          <button
            @click="changePage(2)"
            type="button"
            class="btn-ghost"
          >{{$t('content.common.button.btn03')}}</button>
          <button
            @click="applicate"
            :disabled="!privatedAgreement || mobileNumber.length === 0"
            type="button"
            class="btn-basic"
          >{{$t('content.common.button.btn22')}}</button>
        </template>
      </div>
      <!-- // 버튼 -->
    </template>

    <template v-if="statusType === 4">
      <div class="reservation-result-wrap">
        <div class="reservation-result" data-aos="flip-right">
          <dl class="completed">
            <dt>{{$t('content.event.EventReservation.txt119')}}</dt>
            <dd class="desc" v-html="$t('content.event.EventReservation.txt120')">

            </dd>
          </dl>
        </div>
      </div>
      <div class="btn-wrap perspective">
        <button @click="goHistory" class="btn-basic">{{$t('content.common.button.btn04')}}</button>
      </div>
    </template>
    <template v-if="statusType === 5">
      <!-- 대관 신청 결과 -->
      <div class="reservation-result-wrap">
        <div class="reservation-result" data-aos="flip-right">
          <dl class="failed">
            <dt>{{$t('content.event.EventReservation.txt121')}}</dt>
            <dd class="desc" v-html="$t('content.event.EventReservation.txt122')">

            </dd>
          </dl>
        </div>
      </div>
      <!-- // 대관 신청 결과 -->
      <div class="btn-wrap perspective">
        <router-link :to="{name:'ReservationApplication'}" type="button" class="btn-basic">
          {{$t('content.event.EventReservation.txt123')}}
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import { APIs, FileCategory } from '@/common/portalApi';
import { mixin as mixinHelperUtils, mixinMotion } from '@/common/helperUtils';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import BaseInputElement from '@/components/base/BaseInputElement.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import TelInput from '@/components/base/TelInput.vue';
import BaseRadioButton from '@/components/base/BaseRadioButton.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseTextarea from '@/components/base/BaseTextarea.vue';
import FileInput from '@/components/base/FileInputMultiple.vue';
import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ReservationLegal from '@/views/legal/Reservation.vue';

export default {
  name: 'ReservationApply',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    BaseLabelElement,
    BaseInputElement,
    BaseInput,
    BaseTextarea,
    BaseRadioButton,
    BaseCheckbox,
    TelInput,
    FileInput,
    ValidationObserver,
    ValidationProvider,
    ReservationLegal,
  },
  props: {
    rooms: {
      type: Array,
      required: true,
    },
    startTime: {
      type: Object,
      required: true,
    },
    endTime: {
      type: Object,
      required: true,
    },
    isReservation: {
      type: Boolean,
      required: false,
    },
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      // ui: helperUtils.UI,
      statusType: 1,
      fileName: '',
      fileData: undefined,
      fileList: [],
      fileId: null,
      userInfo: undefined,
      mobileNumber: '',
      enabledInput: false,
      spaceAgreement: undefined,
      privatedAgreement: undefined,
      memberAddMobileYn: false,
      eventName: '',
      eventDesc: '',
      sponsor: '',
      eventParticipantIntroduce: '',
      expectParticipantCount: '',
      useMic: false,
      codeSeatSetting: 3551,
      otherSeatSetting: '',
      isBringCatering: false,
      otherInquiry: '',
      attachments: '',
      isMobile: false,
      codeDivisionCenter: 'CT101',
      useTool: [],
    };
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo();
    const mobileNumber0 = this.userInfo.mobile_number0 || '';
    const mobileNumber1 = this.userInfo.mobile_number1 || '';
    const mobileNumber2 = this.userInfo.mobile_number2 || '';
    this.phone = `${mobileNumber0}${mobileNumber1}${mobileNumber2}`;
    this.enabledInput = this.phone === '';
  },
  mounted() {
    if (this.isReservation) {
      this.showAlert(this.$t('content.event.EventReservation.alertMsg.msg2'), () => {
        this.$emit('input', 1);
      });
      return;
    }
    if (!this.rooms || !this.startTime || !this.endTime) {
      this.showAlert(this.$t('content.event.EventReservation.alertMsg.msg3'), () => {
        this.$emit('input', 1);
      });
    }
    // 메일홀이 아닐 경우 좌석세팅 기타로 설정
    if (!this.rooms.includes('6')) {
      this.codeSeatSetting = 3553;
    }
  },
  methods: {
    changePage(index) {
      this.statusType = index;
    },
    Rearrangement(allRoom, room) {
      let type;
      switch (room) {
        case '6':
          type = 'HALL';
          break;
        case '7':
          type = 'A';
          break;
        case '8':
          type = 'B';
          break;
        case '9':
          type = 'C';
          break;
        case '10':
        case 'CT207':
          type = 'D';
          break;
        case '11':
        case 'CT208':
          type = 'E';
          break;
        default:
      }

      let idx = -1;
      if (type === 'D' && allRoom.includes('E')) {
        idx = allRoom.indexOf('E');
      } else if (type === 'E' && allRoom.includes('D')) {
        idx = allRoom.indexOf('D');
      }
      if (idx > -1) {
        allRoom.splice(idx, 1, 'DE');
      }
      return [...allRoom, type];
    },
    async applicate() {
      const borrowPlaceInfo = [];
      let point = 0;
      const hours = this.endTime.diff(this.startTime, 'hour');

      this.rooms.reduce(this.Rearrangement, []).forEach((v) => {
        if (this.userInfo.member_role === 'MB202') {
          if (v === 'HALL') {
            point += 240000 * hours;
          } else if (v === 'D' || v === 'E') {
            point += 80000 * hours;
          } else if (v === 'DE') {
            point += 240000 * hours;
          } else {
            point += 40000 * hours;
          }
        }
      });
      this.rooms.reduce(this.Rearrangement, []);

      let reservedPlaceIds = [];
      this.rooms.forEach((v) => {
        reservedPlaceIds.push(v);
        // borrowPlaceInfo.push({
        //   code_place_event: v,
        //   borrow_start_time: this.$moment(this.startTime).format('HHmm'),
        //   borrow_end_time: this.$moment(this.endTime).format('HHmm'),
        // });
      });

      // const mobileNumber = this.mobileNumber.split('-');
      let useToolString = '';
      this.useTool.forEach((v) => {
        if (!v) return;
        useToolString += `${v},`;
      });

      console.log(this.userInfo);
      const params = {
        memberId: this.userInfo.id,
        eventName: this.eventName,
        eventDesc: this.eventDesc,
        sponsor: this.sponsor,
        eventParticipantIntroduce: this.eventParticipantIntroduce,
        expectParticipantCount: this.expectParticipantCount,
        useMic: this.useMic,
        codeSeatSetting: this.codeSeatSetting,
        otherSeatSetting: this.otherSeatSetting,
        isBringCatering: this.isBringCatering,
        otherInquiry: this.otherInquiry,
        phone: this.mobileNumber,
        // mobile_number0: mobileNumber[0] || '',
        // mobile_number1: mobileNumber[1] || '',
        // mobile_number2: mobileNumber[2] || '',
        memberAddMobileYn: this.memberAddMobileYn,
        startTime: this.$moment(this.startTime).format('YYYY.MM.DD HH:mm'),
        endTime: this.$moment(this.endTime).format('YYYY.MM.DD HH:mm'),
        reservedPlaceIds: reservedPlaceIds.join(','),
        // point,
        toolIds: useToolString.slice(0, -1),
        fileList: this.fileList
      };

      const res = await this.portalApiClient({
        url: APIs.CENTER_GANGNAM_VENUE_APPLY,
        data: params,
      });

      if (res.data.result) {
        this.statusType = 4;
        /**
         * 신청건에 대한 재신청을 막기위한 플래그 값을 지정함
         */
        this.$emit('reservation');
      } else {
        // this.statusType = 5;
        this.showAlert(res.data.message);
      }
    },
    async selectFile(file) {
      if (file) {
        if (file.size > 10485760) {
          this.showAlert(this.$t('content.event.EventReservation.alertMsg.msg4'));
          return;
        }
        // 파일 형식 체크
        const res = await this.uploadFile(
          FileCategory.EVENTHALL,
          2, // attachement
          0,
          file,
        )
        
        if (res.result) {
          this.fileList.push({
            id: res.data.id,
            name: file.name,
            fileData: file
          })
          this.$refs.attachementFile.onFileAdd(file)
        } else if (res.code == '202') {
          this.showAlert('파일 형식은 ".hwp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .csv, .jpg, .jpeg, .gif, .png, .bmp, .pdf"으로 가능합니다');
        }
      }
    },
    removeFile(index) {
      this.fileList.splice(index, 1);
    },
    goHistory() {
      // this.$router.replace({ name: 'ReservationHistory' });
      this.$emit('input', 1);
    },
    goBack(event) {
      event.preventDefault();
      this.showConfirm(this.$t('content.event.EventReservation.alertMsg.msg5'), () => {
        this.$router.go(-1);
      });
    },
  },
};
</script>
